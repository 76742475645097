/*
    ----------
*/
$Font : (
    "Default" : "Inter"
);
/* 
    ----------
*/
$Size : (
    "10" : 10px,
    "11" : 11px,
    "12" : 12px,
    "13" : 13px,
    "14" : 14px,
    "15" : 15px,
    "16" : 16px,
    "17" : 17px,
    "18" : 18px,
    "19" : 19px,
    "20" : 20px
);
/* 
    ---------- 
*/
$Color : (
    "Default" : #1B84FF,
    "White"   : #FFFFFF,
    "Black"   : #252F4A,
    "Grey"    : #99A1B7,
    "Light"   : #FCFCFC
);
/* 
    ----------
*/
.dE5574{ font-family: map-get( $Font, "Default" ) }
/* 
    ----------
*/
.S6pt64{ float: left  }
.yJ452A{ float: right }
.eTt257{ float: none  }
/* 
    ----------
*/
.q7h2M9{ width: 25%  }
.S09r6B{ width: 50%  }
.b7P443{ width: 75%  }
.C96Q4r{ width: 100% }
/* 
    ----------
*/
.eU7H63{ font-size: map-get( $Size, "10" ) }
.S7gw68{ font-size: map-get( $Size, "11" ) }
.iI0402{ font-size: map-get( $Size, "12" ) }
.xG0j09{ font-size: map-get( $Size, "13" ) }
.PlO170{ font-size: map-get( $Size, "14" ) }
.wI860z{ font-size: map-get( $Size, "15" ) }
.Wm8G06{ font-size: map-get( $Size, "16" ) }
.bS8S40{ font-size: map-get( $Size, "17" ) }
.s4U1j1{ font-size: map-get( $Size, "18" ) }
.J589Ta{ font-size: map-get( $Size, "19" ) }
.i260Up{ font-size: map-get( $Size, "20" ) }
/* 
    ----------
*/
.C2y88Q{ font-weight: lighter }
.u37E41{ font-weight: normal  }
.Y4674t{ font-weight: bold    }
.zK3920{ font-weight: bolder  }
/* 
    ----------
*/
.F2n16X{ font-weight: 100 }
.Y8k86e{ font-weight: 200 }
.y3Or88{ font-weight: 300 }
.nS533w{ font-weight: 400 }
.K0cW96{ font-weight: 500 }
.D933nr{ font-weight: 600 }
.jS8601{ font-weight: 700 }
.Zv29i2{ font-weight: 800 }
.Is1259{ font-weight: 900 }
/* 
    ----------
*/
.J284Xb{ font-style: normal  }
.Ep1U39{ font-style: oblique }
.R6t03r{ font-style: italic  }
/* 
    ----------
*/
.v6EU83{ color: map-get( $Color, "Default" ) }
.M99v3q{ color: map-get( $Color, "White" )   }
.vOx602{ color: map-get( $Color, "Black" )   }
.Y0u334{ color: map-get( $Color, "Grey" )    }
.r4K8f4{ color: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.cvC365{ fill: map-get( $Color, "Default" ) }
.f8T6I8{ fill: map-get( $Color, "White" )   }
.sRU313{ fill: map-get( $Color, "Black" )   }
.Vr4196{ fill: map-get( $Color, "Grey" )    }
.b29YY3{ fill: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.u0Bc59{ background: map-get( $Color, "Default" ) }
.rP408N{ background: map-get( $Color, "White" )   }
.u3I753{ background: map-get( $Color, "Black" )   }
.i3eE58{ background: map-get( $Color, "Grey" )    }
.gZ959C{ background: map-get( $Color, "Light" )   }
/* 
    ----------
*/
.wDC543{ display: inline-block }
.eF429z{ display: inline-flex  }
.xA723K{ display: table-cell   }
.X35k4K{ display: table-row    }
.bN2p30{ display: inline       }
.S0u16d{ display: flex         }
.V81zg1{ display: block        }
.oi8E43{ display: grid         }
.zD661i{ display: table        }
.t3c0N7{ display: -webkit-box  }
.hPP928{ display: none         }
.s6D202{ display: list-item    }
/* 
    ----------
*/
.fE0d48{ flex-direction: column         }
.L640Ti{ flex-direction: column-reverse }
.Y065Fd{ flex-direction: row-reverse    }
.fr598R{ flex-direction: row            }
/* 
    ----------
*/
.ZH339f{ justify-content: flex-start    }
.U080Kf{ justify-content: flex-end      }
.vR05U6{ justify-content: center        }
.g1aS48{ justify-content: space-between }
.h18Ea4{ justify-content: space-around  }
.aR51Y0{ justify-content: space-evenly  }
/* 
    ----------
*/
.I4t79h{ align-items: normal     }
.nA9305{ align-items: stretch    }
.d721Vx{ align-items: center     }
.Q3n44O{ align-items: flex-start }
.J7l0G2{ align-items: flex-end   }
.W44s9p{ align-items: start      }
.C6u87n{ align-items: end        }
.Q7Ae62{ align-items: baseline   }
/* 
    ----------
*/
.xD280K{ text-align: start   }
.Q606yt{ text-align: end     }
.vC9r15{ text-align: center  }
.yyO233{ text-align: justify }
/* 
    ----------
*/
.uUG847{ text-transform: lowercase  }
.GzJ197{ text-transform: uppercase  }
.cMq044{ text-transform: capitalize }
.e2M03y{ text-transform: none       }
/*
    ----------
*/
.vF5k47{ text-decoration: none         }
.F83vw7{ text-decoration: dashed       }
.a20K95{ text-decoration: dotted       }
.C71Dm7{ text-decoration: double       }
.El6174{ text-decoration: solid        }
.r9zX81{ text-decoration: overline     }
.sB248t{ text-decoration: wavy         }
.Q3s2S1{ text-decoration: underline    }
.h6T64Y{ text-decoration: line-through }
/*
    ----------
*/
.cB3332{ text-underline-offset: 6px }
/*
   ----------
*/
.q8K60t{ text-overflow: ellipsis }
.faJ087{ text-overflow: clip     }
.m1BW70{ text-overflow: unset    }
/*
    ----------
*/
.U596Kr{ vertical-align: baseline    }
.t0G834{ vertical-align: sub         }
.ebZ371{ vertical-align: super       }
.I7e03O{ vertical-align: top         }
.j844Eb{ vertical-align: text-top    }
.eQ738Y{ vertical-align: middle      }
.E0vm38{ vertical-align: bottom      }
.z56Q50{ vertical-align: text-bottom }
/*
    ----------
*/
.gL0474{ white-space: normal }
.wqA166{ white-space: nowrap }
.Q7a1D3{ white-space: pre    }
/* 
    ----------
*/
.cH8711{ position: relative }
.gBt584{ position: absolute }
.p9V85g{ position: sticky   }
.uK7E77{ position: static   }
.Ude336{ position: fixed    }
/*
    ----------
*/
.x35Ac2{ overflow: visible }
.J057Si{ overflow: hidden  }
.jC732U{ overflow: scroll  }
.L9Rm29{ overflow: auto    }
/*
    ----------
*/
.yL0811{ cursor: alias         }
.v6CN61{ cursor: all-scroll    }
.qE800M{ cursor: auto          }
.tJ204O{ cursor: cell          }
.sDN443{ cursor: col-resize    }
.p8C19l{ cursor: context-menu  }
.E0m26B{ cursor: copy          }
.L567nt{ cursor: crosshair     }
.Ri032C{ cursor: default       }
.kD889h{ cursor: e-resize      }
.S6u0h5{ cursor: ew-resize     }
.c2Q91N{ cursor: grab          }
.dZ9M00{ cursor: grabbing      }
.gM266u{ cursor: help          }
.dP797B{ cursor: move          }
.j3Uh65{ cursor: n-resize      }
.cZ180w{ cursor: ne-resize     }
.aH3174{ cursor: nw-resize     }
.j53Z9D{ cursor: nwse-resize   }
.Gp2446{ cursor: no-drop       }
.Lr9809{ cursor: not-allowed   }
.N4g4m6{ cursor: pointer       }
.k6G6h5{ cursor: progress      }
.Yri682{ cursor: row-resize    }
.Zd4o45{ cursor: s-resize      }
.X2R6j8{ cursor: se-resize     }
.p2M9c8{ cursor: sw-resize     }
.Xw0F20{ cursor: text          }
.Q6j5W4{ cursor: vertical-text }
.S344z0{ cursor: w-resize      }
.z9V38t{ cursor: wait          }
.Ac102a{ cursor: zoom-in       }
.d6F48n{ cursor: zoom-out      }
.h30X35{ cursor: none          }
/*
    ----------
*/
.Kg80G9{ word-wrap: normal     }
.uQ136M{ word-wrap: break-word }
/*
    ----------
*/
.z9X51G{ pointer-events: none }
.Bj1642{ pointer-events: auto }
/*
    ----------
*/
.oQ637t{ border-style: dotted }
.r380oV{ border-style: dashed }
.F5m883{ border-style: solid  }
.Fb292w{ border-style: double }
.Fcx262{ border-style: groove }
.eS845b{ border-style: ridge  }
.L2sq24{ border-style: inset  }
.t3KL23{ border-style: outset }
.Z7o516{ border-style: none   }
.Wx078J{ border-style: hidden }
/*
    ----------
*/
.ob2A34{ border:        0 }
.xq62J3{ border-top:    0 }
.PvN976{ border-bottom: 0 }
.rJ26U8{ border-left:   0 }
.LjJ561{ border-right:  0 }
/*
    ----------
*/
.L72y8b{ border-collapse: collapse }
.Z5y7o1{ border-collapse: separate }
/*
    ----------
*/
.m9X7C5{ margin: 0 auto }
/*
    ----------
*/
.pEO064{ margin:        0 }
.vSV800{ margin-left:   0 }
.D797aR{ margin-right:  0 }
.sX6873{ margin-top:    0 }
.B2d81S{ margin-bottom: 0 }
/*
    ----------
*/
.a527eB{ padding:        0 }
.z8o5S4{ padding-top:    0 }
.dW8M00{ padding-right:  0 }
.C7D19o{ padding-left:   0 }
.R3a5i0{ padding-bottom: 0 }
/*
    ----------
*/
.GO52g1{ border-radius: 0    }
.g03C00{ border-radius: 25%  }
.K79e1C{ border-radius: 50%  }
.pZR189{ border-radius: 75%  }
.kZm977{ border-radius: 100% }
/*
    ----------
*/
.wzU404{ flex: 0 0 auto }
.uTM149{ flex: 1 1 auto }
.U977pl{ flex: 0 1 auto }
.z097Vp{ flex: 1 0 auto }
/*
    ----------
*/
.P1470a{ flex-grow: 0 }
.iw2U41{ flex-grow: 1 }
.I750Xd{ flex-grow: 2 }
.S276Td{ flex-grow: 3 }
/*
    ----------
*/
.X594yq{ flex-basis: 1 }
.U716Tv{ flex-basis: 2 }
.Rzr931{ flex-basis: 3 }
/*
    ----------
*/
.o9T8I8{ flex-wrap: wrap         }
.R0X85t{ flex-wrap: nowrap       }
.Fv9Z90{ flex-wrap: wrap-reverse }
/*
    ----------
*/
.vE6956{ order: 1 }
.XjX424{ order: 2 }
.W85it5{ order: 3 }
/*
    ----------
*/
.Q330w3{ align-content: stretch       }
.v69MS6{ align-content: center        }
.X19jp8{ align-content: space-evenly  }
.v74JJ5{ align-content: flex-start    }
.uH27J4{ align-content: flex-end      }
.T3l2s4{ align-content: space-between }
.D123Qj{ align-content: space-around  }
/*
    ----------
*/
.j1Mr03{ flex-shrink: 0 }
.n7OS97{ flex-shrink: 1 }
/*
    ----------
*/
.Ax30Q1{ box-shadow: none }
/*
    ----------
*/
.J55M9e{ content: "" }
/* 
    ----------
*/
.C4v153{ outline: 0    }
.sD881L{ outline: auto }
/* 
    ----------
*/
.pL4U70{ opacity: 0    }
.q693Lc{ opacity: 25%  }
.aT591i{ opacity: 50%  }
.S8k6H9{ opacity: 75%  }
.aV498I{ opacity: 100% }
/*
    ----------
*/
.cB943h{ line-clamp: 2 }
.Bt6232{ line-clamp: 3 }
.X2a9R4{ line-clamp: 4 }
/*
    ----------
*/
.gDk539{ -webkit-box-orient: block-axis  }
.J4i292{ -webkit-box-orient: inline-axis }
.uQ1c23{ -webkit-box-orient: horizontal  }
.G7o96W{ -webkit-box-orient: vertical    }
/*
    ----------
*/
.c8R6W7{ list-style: decimal-leading-zero }
.WvX121{ list-style: lower-alpha          }
.Iy85Z8{ list-style: lower-greek          }
.X6y6H0{ list-style: lower-latin          }
.xT170D{ list-style: lower-roman          }
.J564hZ{ list-style: upper-alpha          }
.Qd352p{ list-style: upper-latin          }
.Np616D{ list-style: upper-roman          }
.W18b0l{ list-style: disc                 }
.bR099N{ list-style: georgian             }
.Jd2580{ list-style: inside               }
.R723p4{ list-style: outside              }
.dK9146{ list-style: square               }
.h57Y8Q{ list-style: circle               }
.kO539q{ list-style: armenian             }
.vY001R{ list-style: decimal              }
.Au5718{ list-style: numeric              }
.GnG350{ list-style: none                 }
/*
    ----------
*/
.C196Fv{ writing-mode: horizontal-tb }
.Z95a8x{ writing-mode: vertical-lr   }
.x0F757{ writing-mode: vertical-rl   }
/*
    ----------
*/
.Klc797{ transition: all .8s ease }
/*
    ----------
*/
.t0T2S2{ transform: none           }
.wD3v95{ transform: rotate(90deg)  }
.Zka424{ transform: rotate(180deg) }
.v1M46U{ transform: rotate(270deg) }
.j833Ma{ transform: rotate(360deg) }
/*
    ----------
*/
.jX0924{ object-fit: fill       }
.C0go67{ object-fit: contain    }
.Mg1457{ object-fit: cover      }
.uX61k8{ object-fit: scale-down }
.y60E9p{ object-fit: none       }
/* 
    ----------
*/
.qW182T{ line-height: 1.0 }
.tSz942{ line-height: 1.5 }
.YS535e{ line-height: 2.0 }
/*
    ----------
*/
.SFq502{ background-position: left top      }
.iJM161{ background-position: left center   }
.Gr070F{ background-position: left bottom   }
.Px402I{ background-position: right top     }
.t6K867{ background-position: right center  }
.mG848G{ background-position: right bottom  }
.J2l421{ background-position: center top    }
.F710rg{ background-position: center center }
.vW8q13{ background-position: center bottom }
/*
    ----------
*/
.Tq143x{ background-size: auto    }
.T659Fi{ background-size: cover   }
.B4A44p{ background-size: contain }
/*
    ----------
*/
.P6cE22{ background-repeat: repeat    }
.XcJ938{ background-repeat: repeat-x  }
.rYx754{ background-repeat: repeat-y  }
.N965Go{ background-repeat: no-repeat }
.rG790K{ background-repeat: space     }
.kV124B{ background-repeat: round     }
/*
    ----------
*/
.P779mc{ box-sizing: border-box }
/*
    ----------
*/
.H9g95G{ word-break: normal     }
.kO189r{ word-break: break-all  }
.wK1970{ word-break: keep-all   }
.kL64Z1{ word-break: break-word }
/*
    ----------
*/
.q629Gd{ filter: brightness(0.5)   }
.jSO180{ filter: contrast(160%)     }
.H61jT8{ filter: saturate(3)        }
.wM7o82{ filter: invert(100%)       }
.Q8b7e3{ filter: grayscale(50%)     }
.T354Vs{ filter: sepia(100%)        }
.U029ho{ filter: blur(3px)          }
.o98b8U{ filter: hue-rotate(142deg) }
/*
    ----------
*/
.C23pq8{ resize: both       }
.qS03n2{ resize: none       }
.Ss37t2{ resize: horizontal }
.n3R12l{ resize: vertical   }
/*
    ----------
*/
@mixin Size( $Width, $Height ){ 
    width  : $Width; 
    height : $Height 
}
@mixin Start( $Start, $Top ){ 
    left : $Start; 
    top  : $Top 
}
@mixin End( $End, $Top ){ 
    right : $End; 
    top   : $Top 
}
@mixin Top( $Start, $Bottom ){ 
    left   : $Start; 
    bottom : $Bottom 
}
@mixin Bottom( $End, $Bottom ){ 
    right  : $End; 
    bottom : $Bottom 
}
@mixin Margin( $Top, $End, $Bottom, $Start ){ 
    margin: $Top $End $Bottom $Start 
}
@mixin Padding( $Top, $End, $Bottom, $Start ){ 
    padding: $Top $End $Bottom $Start 
}
/* 
    ----------
*/
:root{
    --css-3m71ky : 0;
    --css-z44516 : 0;
    --css-80k04n : 21px;
}
:root{ 
    --css-c8g166 : #DBDFE9;
    --css-y1f1t1 : #F1F1F4;
    --css-04j8x5 : #F4F6FA;
    --css-y3m240 : #F9F9F9;
    --css-y91y63 : #000000;
    --css-u2260x : #4B5675;
    --css-f9079a : #99A1B7;
    --css-k87om2 : #071437;
    --css-t6jv56 : #F8285A;
    --css-fu4171 : #E9F3FF;
}
/* 
    ----------
*/
html{
    @extend .dE5574;
}
a{
    @extend .v6EU83;
    @extend .vF5k47;
}
a:hover{
    @extend .Q3s2S1;
}
/* 
    ----------
*/
body{
    @extend .dE5574;
    @extend .Wm8G06;
    @extend .gZ959C;
}
/* 
    ----------
*/
.vh1695{
    @include Size( 100%, 100vh );
    @extend .Klc797;
    .rdx705{
        @include Size( 100%, 100vh );
        background: var(--css-y1f1t1);
        .x6e705{
            @include Size( 100%, 100vh );
            @extend .Klc797;
            .b8z233{
                @include Size( 100%, auto );
                @extend .Klc797;
                .j142ro{
                    @extend .jS8601;
                    font-size: var(--css-80k04n);
                    color: var(--css-k87om2);
                    line-height: 27px;
                }
                .o179ee{
                    @extend .K0cW96;
                    @extend .Y0u334;
                    @extend .xG0j09;
                    line-height: 23px;
                }
            }
            .a7612o{
                @include Size( 100%, auto );
                @extend .Klc797;
                .o54h06{
                    @include Size( 100%, 44px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .rP408N;
                    border: 1px solid var(--css-c8g166);
                    max-width: 213px;
                    border-radius: 6px;
                    .e68c9l{
                        @include Size( auto, auto );
                        @extend .Klc797;
                        svg{
                            @include Size( 15px, 15px );
                            @extend .Klc797;
                        }
                    }
                    .f816t2{
                        @include Size( auto, auto );
                        @extend .Klc797;
                        @extend .xG0j09;
                        @extend .vOx602;
                        @extend .K0cW96;
                    }
                    &:hover .f816t2{
                        @extend .v6EU83;
                    }
                }
                .q336d4{
                    @include Size( 100%, 44px );
                    @extend .Klc797;
                    @extend .C4v153;
                    @extend .rP408N;
                    border: 1px solid var(--css-c8g166);
                    max-width: 213px;
                    border-radius: 6px;
                    .r94f3m{
                        @include Size( auto, auto );
                        @extend .Klc797;
                        svg{
                            @include Size( 15px, 15px );
                            @extend .Klc797;
                        }
                    }
                    .g3ov57{
                        @include Size( auto, auto );
                        @extend .Klc797;
                        @extend .xG0j09;
                        @extend .vOx602;
                        @extend .K0cW96;
                    }
                    &:hover .g3ov57{
                        @extend .v6EU83;
                    }
                }
            }
            .ou9216{
                @include Size( 100%, auto );
                @extend .Klc797;
                .v39h05{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    max-width: 434px;
                    .yq281l{
                        @extend .K0cW96;
                        @extend .Y0u334;
                        @extend .iI0402;
                        min-width: 100px;
                    }
                    &::before{
                        @include Size( 50%, 1px );
                        @extend .S6pt64;
                        @extend .J55M9e;
                        background: var(--css-c8g166);
                        margin: 0 16px 0 0;
                    }
                    &::after{
                        @include Size( 50%, 1px );
                        @extend .yJ452A;
                        @extend .J55M9e;
                        background: var(--css-c8g166);
                        margin: 0 0 0 16px;
                    }
                }
            }
            .euw985{
                @include Size( 100%, auto );
                @extend .Klc797;
                .c479ca{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    max-width: 434px;
                }
                .d835tf{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .y9sw59{
                        @include Size( 100%, 44px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .rP408N;
                        @extend .xG0j09;
                        @extend .vOx602;
                        border: 1px solid var(--css-c8g166);
                        border-radius: 6px;
                        &::placeholder{
                            @extend .Y0u334;
                            @extend .aV498I;
                        }
                    }
                    .p2076q{
                        @include Size( 100%, 44px );
                        @extend .Klc797;
                        @extend .C4v153;
                        @extend .u0Bc59;
                        @extend .ob2A34;
                        @extend .xG0j09;
                        @extend .M99v3q;
                        @extend .K0cW96;
                        border-radius: 6px;
                        &:hover{
                            @extend .S8k6H9;
                        }
                    }
                    .k5h5x5{
                        @extend .xG0j09;
                        @extend .v6EU83;
                        @extend .K0cW96;
                        &:hover{
                            @extend .cB3332;
                        }
                    }
                    .c1a625{
                        @extend .xG0j09;
                        @extend .Y0u334;
                        @extend .K0cW96;
                    }
                    .tr04h0{
                        @extend .xG0j09;
                        @extend .v6EU83;
                        @extend .K0cW96;
                        &:hover{
                            @extend .cB3332;
                        }
                    }
                }
            }
        }
    }
    .mk89k1{
        @include Size( 100%, 100vh );
        @extend .T659Fi;
        @extend .iJM161;
        @extend .N965Go;
        background-image: url( "./Media/Image/002.png" );
        .p4h737{
            @include Size( 100%, 100vh );
            @extend .Klc797;
            svg{
                @include Size( 420px, 420px );
                @extend .Klc797;
                path{
                    @extend .Klc797;
                    @extend .f8T6I8;
                }
            }
        }
    }
}
/* 
    ----------
*/
.h723jp{
    @include Size( 100%, 350px );
    @extend .N965Go;
    @extend .J2l421;
    background-image: url( "./Media/Image/001.jpg" );
    background-size: 100% 350px;
    .r0062c{
        @include Size( 100%, 80px );
        @extend .Klc797;
        border: 1px solid rgba( map-get( $Color, "White" ), .1 );
        .b177uj{
            @include Size( 100%, 80px );
            @extend .Klc797;
            .t7b379{
                @include Size( 100%, 80px );
                @extend .Klc797;
                @extend .C4v153;
                svg{
                    @include Size( 30px, auto );
                }
            }
        }
        .k347lf{
            @include Size( 100%, 80px );
            @extend .GnG350;
            li{
                a{
                    @include Size( auto, 39px );
                    @extend .xG0j09;
                    @extend .M99v3q;
                    @extend .K0cW96;
                    @extend .Klc797;
                    @extend .vF5k47;
                    margin: 0 9px 0 0;
                    padding: 9.75px 15px;
                    border-radius: 5px;
                    &:hover{
                        background: rgba( map-get( $Color, "White" ), .1 );
                    }
                }
                .yj724o{
                    @include Start( 0, 40px );
                    @include Size( 100%, auto );
                    @extend .rP408N;
                    @extend .GnG350;
                    @extend .GO52g1;
                    @extend .hPP928;
                    min-width: 270px;
                    z-index: 24;
                    li{
                        a{
                            @include Size( auto, 34px );
                            @extend .vOx602;
                            @extend .a527eB;
                            @extend .iI0402;
                            margin: 4px 0;
                            border-bottom: 1px solid var(--css-y1f1t1);
                            line-height: 34px;
                            svg{
                                @include Size( 13px, auto );
                            }
                        }
                        &:last-child a{
                            @extend .ob2A34;
                        }
                        &:hover ul{
                            @extend .V81zg1;
                        }
                    }
                    ul{
                        @include Start( 270px, 0px );
                        @include Size( 100%, auto );
                        @extend .rP408N;
                        @extend .GnG350;
                        @extend .GO52g1;
                        @extend .hPP928;
                        min-width: 270px;
                        z-index: 24;
                    }
                }
                &:first-child a{
                    background: rgba( map-get( $Color, "White" ), .1 );
                }
                &:hover .yj724o{
                    @extend .V81zg1;
                }
            }
        }
        .m95t97{
            @include Size( 100%, 80px );
            @extend .GnG350;
            li{
                a{
                    @include Size( 100%, 39px );
                    @extend .Klc797;
                    border-radius: 5px;
                    max-width: 39px;
                    min-width: 39px;
                    svg{
                        @include Size( 19px, 19px );
                        @extend .Klc797;
                        path{
                            @extend .f8T6I8;
                        }
                    }
                    &:hover{
                        background: rgba( map-get( $Color, "White" ), .1 );
                    }
                }
                &:nth-child(3) a svg{
                    @include Size( 18px, 18px );
                }
                &:last-child a{
                    background: rgba( map-get( $Color, "White" ), .1 );
                }
            }
        }
    }
    .i6d476{
        @include Size( 100%, calc( 350px - 160px ) );
        @extend .Klc797;
        .y5q57d{
            @include Size( 100%, calc( 350px - 160px ) );
            @extend .Klc797;
            .j580ji{
                @extend .bS8S40;
                @extend .M99v3q;
                @extend .K0cW96;
            }
            .ji6562{
                @extend .iI0402;
                @extend .M99v3q;
                @extend .nS533w;
                @extend .S8k6H9;
                line-height: 24px
            }
        }
    }
}
/* 
    ----------
*/
.v9339k{
    @include Size( 100%, auto );
    @extend .Klc797;
    .q0r45m{
        @include Size( 100%, auto );
        @extend .Klc797;
        .e475k3{
            @include Size( 100%, 195.99px );
            @extend .rP408N;
            margin: -80px 0 0 0;
            padding: 48px 48px;
            border: 1px solid var(--css-y1f1t1);
            box-shadow: 0px 3px 4px 0px rgba( var(--css-y91y63), .03 );
            border-radius: 0.625rem;
            .rv3198{
                @extend .wI860z;
                @extend .vOx602;
                @extend .K0cW96;
            }
            .hm1203{
                @extend .xG0j09;
                color: var(--css-f9079a);
                line-height: 23px
            }
        }
    }
    .e279bh{
        @include Size( 100%, auto );
        @extend .Klc797;
        .w60023{
            @include Size( 100%, auto );
            @extend .rP408N;
            @extend .a527eB;
            @extend .pEO064;
            border: 1px solid var(--css-y1f1t1);
            box-shadow: 0px 3px 4px 0px rgba( var(--css-y91y63), .03 );
            border-radius: 0.625rem;
            .k3gq53{
                @extend .xG0j09;
                @extend .vOx602;
                @extend .K0cW96;
                border-bottom: 1px dashed var(--css-c8g166);
                line-height: 24px;
            }
            .z336ry{
                @include Size( 100%, auto );
                @extend .Klc797;
                .eoj420{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    border-bottom: 1px dashed var(--css-c8g166);
                    .xln744{
                        @include Size( 100%, auto );
                        background: var(--css-y3m240);
                        max-width: 72px;
                    }
                    .j9i516{
                        @include Size( 100%, 100% );
                        @extend .Klc797;
                        .c432qc{
                            @extend .xG0j09;
                            @extend .vOx602;
                            @extend .K0cW96;
                        }
                        .z9815k{
                            @extend .xG0j09;
                            @extend .K0cW96;
                            color: var(--css-f9079a);
                        }
                        .p6172g{
                            @include Size( 100%, 35px );
                            @extend .ob2A34;
                            background: var(--css-y3m240);
                            max-width: 35px;
                            border-radius: 5px;
                            svg{
                                @include Size( 16px, 16px );
                                rect{
                                    fill: var(--css-f9079a);
                                }
                                path{
                                    fill: var(--css-f9079a);
                                }
                            }
                        }
                    }
                }
                .eoj420:last-child{
                    @extend .ob2A34;
                }
            }
        }
        .b129uj{
            @include Size( 100%, auto );
            @extend .rP408N;
            @extend .a527eB;
            @extend .pEO064;
            border: 1px solid var(--css-y1f1t1);
            box-shadow: 0px 3px 4px 0px rgba( var(--css-y91y63), .03 );
            border-radius: 0.625rem;
            .el8h94{
                @extend .xG0j09;
                @extend .vOx602;
                @extend .K0cW96;
                border-bottom: 1px dashed var(--css-c8g166);
                line-height: 24px;
            }
            .w123l4{
                @include Size( 100%, auto );
                @extend .Klc797;
                .gn9r17{
                    @include Size( 100%, 55px );
                    @extend .Klc797;
                    .t3405h{
                        @include Size( 100%, 35px );
                        @extend .ob2A34;
                        background: var(--css-y3m240);
                        max-width: 35px;
                        border-radius: 5px;
                        svg{
                            @include Size( 16px, 16px );
                            rect{
                                fill: var(--css-f9079a);
                            }
                            path{
                                fill: var(--css-f9079a);
                            }
                        }
                    }
                }
                .j7311w{
                    @include Size( 100%, 55px );
                    @extend .Klc797;
                    .k7y1x5{
                        @extend .xG0j09;
                        @extend .vOx602;
                        @extend .K0cW96;
                    }
                    .e7eg75{
                        @extend .iI0402;
                        color: var(--css-f9079a);
                        line-height: 22px;
                    }
                }
                .f35qk4{
                    @include Size( 100%, 55px );
                    @extend .Klc797;
                    .g24k5j{
                        @include Size( 100%, auto );
                        background: var(--css-y3m240);
                        max-width: 55px;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
    .ga8x38{
        @include Size( 100%, auto );
        @extend .Klc797;
        .d93oi9{
            @include Size( 100%, auto );
            @extend .rP408N;
            margin: -80px 0 0 0;
            padding: 48px 48px;
            box-shadow: 0px 3px 4px 0px rgba( var(--css-y91y63), 0.03 );
            .c971b1{
                @include Size( 100%, auto );
                @extend .rP408N;
                border-bottom: 1px dashed var(--css-c8g166);
                .uh0823{
                    @extend .Wm8G06;
                    @extend .vOx602;
                    @extend .K0cW96;
                    line-height: 21px
                }
                .o6a700{
                    @extend .xG0j09;
                    @extend .Y0u334;
                    @extend .nS533w;
                    line-height: 21px
                }
            }
            .ix0q18{
                @include Size( 100%, auto );
                @extend .Klc797;
                .v2g96a{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .c79t31{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                        .y944m5{
                            @extend .xG0j09;
                            @extend .vOx602;
                            @extend .K0cW96;
                            &::after{
                                content: "*";
                                color: var(--css-t6jv56);
                                margin: 0 0 0 4px;
                            }
                        }
                        .c21d8j{
                            @extend .xG0j09;
                            @extend .vOx602;
                            @extend .K0cW96;
                        }
                        .kq045f{
                            @extend .xG0j09;
                            @extend .vOx602;
                            @extend .K0cW96;
                        }
                        .klf458{
                            @include Size( 100%, auto );
                            @extend .Klc797;
                            img{
                                @include Size( 100%, auto );
                                @extend .Klc797;
                            }
                        }
                        .bs0m02{
                            @include Size( 100%, auto );
                            @extend .Klc797;
                            .p-editor-content{
                                @include Size( 100%, 250px );
                                @extend .Klc797;
                            }
                        }
                        .x174vk{
                            @include Size( 100%, 43px );
                            @extend .Klc797;
                            @extend .C4v153;
                            @extend .xG0j09;
                            background: var(--css-y3m240);
                            border: 1px solid var(--css-y3m240);
                            color: var(--css-u2260x);
                            &::placeholder{
                                @extend .aV498I;
                                color: var(--css-u2260x);
                            }
                            &:focus{
                                background: var(--css-y1f1t1);
                                border: 1px solid var(--css-y1f1t1);
                            }
                        }
                        .f972xq{
                            @include Size( 100%, 43px );
                            @extend .Klc797;
                            @extend .C4v153;
                            @extend .xG0j09;
                            background: var(--css-y3m240);
                            border: 1px solid var(--css-y3m240);
                            border-right: 10px solid transparent;
                            color: var(--css-u2260x);
                        }
                        .a49170{
                            @include Size( 100%, 110px );
                            @extend .Klc797;
                            @extend .C4v153;
                            @extend .xG0j09;
                            background: var(--css-y3m240);
                            border: 1px solid var(--css-y3m240);
                            color: var(--css-u2260x);
                            &::placeholder{
                                @extend .aV498I;
                                color: var(--css-u2260x);
                            }
                            &:focus{
                                background: var(--css-y1f1t1);
                                border: 1px solid var(--css-y1f1t1);
                            }
                        }
                        .k1s43b{
                            @include Size( 100%, 80px );
                            @extend .Klc797;
                            @extend .C4v153;
                            @extend .xG0j09;
                            background: var(--css-y3m240);
                            border: 1px solid var(--css-y3m240);
                            color: var(--css-u2260x);
                            &::placeholder{
                                @extend .aV498I;
                                color: var(--css-u2260x);
                            }
                            &:focus{
                                background: var(--css-y1f1t1);
                                border: 1px solid var(--css-y1f1t1);
                            }
                        }
                        .z4256t{
                            @include Size( 100%, 44px );
                            @extend .Klc797;
                            @extend .C4v153;
                            @extend .u0Bc59;
                            @extend .ob2A34;
                            @extend .xG0j09;
                            @extend .M99v3q;
                            @extend .K0cW96;
                            &:hover{
                                @extend .S8k6H9;
                            }
                        }
                        .y5m320{
                            @include Size( 100%, 44px );
                            @extend .Klc797;
                            @extend .C4v153;
                            @extend .ob2A34;
                            @extend .xG0j09;
                            @extend .v6EU83;
                            @extend .K0cW96;
                            background: var(--css-fu4171);
                            &:hover{
                                @extend .u0Bc59;
                                @extend .M99v3q;
                            }
                        }
                    }
                    .t7095e{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                    }
                }
                .d8z4l3{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .kl579w{
                        @include Size( 100%, 200px );
                        @extend .cH8711;
                        max-width: 200px;
                        svg{
                            @include Size( 200px, 200px );
                        }
                    }
                    .qb483o{
                        @include Size( 100%, 200px );
                        @extend .cH8711;
                        max-width: 200px;
                        svg{
                            @include Size( 100%, auto );
                        }
                    }
                    .r68ub7{
                        @include Size( 100%, auto );
                        @extend .cH8711;
                        svg{
                            @include Size( 100%, auto );
                        }
                    }
                    .f5f48j{
                        @include Size( 100%, 24px );
                        @include End( -12px, -12px );
                        @extend .rP408N;
                        @extend .N4g4m6;
                        box-shadow: rgba( 0, 0, 0, 0.075 ) 0px 6.5px 19.5px 6.5px;
                        max-width: 24px;
                        svg{
                            @include Size( 12px, 12px );
                        }
                    }
                }
            }
            .sh874h{
                @include Size( 100%, auto );
                @extend .Klc797;
                .n2b78e{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                    .d9287e{
                        @include Size( 100%, auto );
                        @extend .Klc797;
                        .po732b{
                            @extend .xG0j09;
                            @extend .Y0u334;
                            @extend .K0cW96;
                        }
                    }
                }
                .p4s8r8{
                    @include Size( 100%, auto );
                    @extend .GnG350;
                    li{
                        span{
                            @extend .xG0j09;
                            @extend .Y0u334;
                            @extend .K0cW96;
                            line-height: 35px
                        }
                        label{
                            @include Size( 100%, 35px );
                            @extend .xG0j09;
                            @extend .K0cW96;
                            color: var(--css-f9079a);
                            background: var(--css-y3m240);
                            border-radius: 5px;
                            min-width: 35px;
                            line-height: 35px
                        }
                        a{
                            @include Size( 100%, 35px );
                            @extend .xG0j09;
                            @extend .K0cW96;
                            @extend .Klc797;
                            color: var(--css-f9079a);
                            background: var(--css-y3m240);
                            border-radius: 5px;
                            max-width: 35px;
                            line-height: 35px;
                            svg{
                                @include Size( 16px, 16px );
                                @extend .Klc797;
                                rect{
                                    @extend .Klc797;
                                    fill: var(--css-f9079a);
                                }
                                path{
                                    @extend .Klc797;
                                    fill: var(--css-f9079a);
                                }
                            }
                        }
                    }
                }
                .jl002z{
                    @include Size( 100%, auto );
                    @extend .Klc797;
                }
            }
        }
    }
    .mp562d{
        @include Size( 100%, auto );
        @extend .Klc797;
    }
}
/* 
    ----------
*/
.m5m20o{
    @include Size( 100%, auto );
    @extend .Klc797;
    .f2r6x3{
        @include Size( 100%, auto );
        @extend .Klc797;
        .qu237a{
            @include Size( 100%, auto );
            @extend .Klc797;
            .r40483{
                @include Size( 100%, auto );
                @extend .xG0j09;
                @extend .nS533w;
                color: var(--css-y91y63);
                line-height: 45px;
            }
            .v69wj1{
                @include Size( 100%, auto );
                @extend .GnG350;
                li{
                    a{
                        @include Size( 100%, auto );
                        @extend .xG0j09;
                        @extend .nS533w;
                        color: var(--css-y91y63);
                        line-height: 45px;
                        &:hover{
                            @extend .cB3332;
                        }
                    }
                }
            }
        }
    }
}
/* 
    ----------
*/
.ck-reset{
    @extend .S6pt64;
    width: 100%!important;
    margin: 8px 0 0 0!important;
}
.ck-editor__editable_inline{
    min-height: 250px !important;
}
.ck-editor__editable_inline{
    @extend .dE5574;
    @extend .PlO170;
    line-height: 24px;
}